import { useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
import LocationDropDown from '../../../../global/components/location-drop-down/LocationDropDown'
import { VAN_IMAGE_TYPES } from '../../../../global/constants/image'
import { getImageBys3ObjectKey } from '../../../api'
import { fetchData } from '../../../../global/utils/fetch'
import { VanContext } from '../../../../global/context/van-context/VanContext'
import moment from 'moment'
import { FormHelperText } from '@mui/material'
import {
  INVENTORY_STATUSES,
  LIVERIES_ARRAY,
  MAX_VIN_LENGTH,
} from '../../../../global/constants/van'
import handleYearChange from '../common/handle-year-change'
import handleVinChange from '../common/handle-vin-change'
import generateMenuItems from '../../../../global/utils/generate-menu-items'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import ChangeOperatingStatusModal from './ChangeOperatingStatusModal'
import determineIsDateValid from '../common/determine-is-date-valid'
import { UserContext } from '../../../../base/components/base-container/BaseContainer'
import hasPermission from '../../../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../../../global/constants/permissions'
import determineIsVinValid from '../../../../global/utils/determine-is-vin-valid'
import determineIsYearValid from '../../../../global/utils/determine-is-year-valid'
import transformDate from '../../common/transform-date'
import formatDate from '../../common/format-date'
import determineIsStringValid from '../common/determine-is-string-valid'
import { BatteryData } from '../../edit-van-modal/form/battery-data/BatteryData'

interface IEditVanFormComponentProps {
  setIsFormDataValid: Function
  setOperatingStatusNote: Function
}

const OPERATING_STATUS_INFO_INVALID_ERROR = [
  'Operating Status and Operating Status Start Date are required (set them by',
  'clicking the "Change Operating Status" button).',
].join(' ')

function EditVanFormComponent({
  setIsFormDataValid,
  setOperatingStatusNote,
}: IEditVanFormComponentProps) {
  const { user } = useContext(UserContext)

  const { vanData, setLocation, setVanData } = useContext(VanContext)
  const {
    dotEndDate,
    images,
    inServiceDate,
    inventoryStatus,
    licensePlate,
    livery,
    location,
    make,
    model,
    name,
    operatingStatus,
    operatingStatusStartDate,
    outOfServiceDate,
    registrationEndDate,
    vin,
    year,
  } = vanData
  const locationObjectId = location?.objectId
  const isVanOutOfInventory =
    inventoryStatus !== INVENTORY_STATUSES.IN_INVENTORY

  const [qrCodeImageUrl, setQrCodeImageUrl] = useState('')
  const [vinError, setVinError] = useState<string>('')
  const [yearError, setYearError] = useState<string>('')
  const [operatingStatusInfoError, setOperatingStatusInfoError] =
    useState<string>('')

  const [isOperatingStatusModalOpen, setIsOperatingStatusModalOpen] =
    useState<boolean>(false)

  useEffect(() => {
    const isDotEndDateValid = determineIsDateValid(dotEndDate)
    const isNameValid = determineIsStringValid(name)
    const isInServiceDateValid = determineIsDateValid(inServiceDate)
    const isLicensePlateValid = determineIsStringValid(licensePlate)
    const isLocationValid = determineIsStringValid(location?.objectId)
    const isMakeValid = determineIsStringValid(make)
    const isModelValid = determineIsStringValid(model)
    const isOperatingStatusValid = determineIsStringValid(operatingStatus)
    const isOperatingStatusStartDateValid = determineIsDateValid(
      operatingStatusStartDate,
    )
    const isRegistrationEndDateValid = determineIsDateValid(registrationEndDate)
    const isVinValid = determineIsVinValid(vin ?? '')
    const isYearValid = determineIsYearValid(Number.parseInt(year ?? ''))

    const tempIsFormDataValid =
      !Boolean(vinError || yearError) &&
      !isVanOutOfInventory &&
      isDotEndDateValid &&
      isNameValid &&
      isInServiceDateValid &&
      isLicensePlateValid &&
      isLocationValid &&
      isMakeValid &&
      isModelValid &&
      isOperatingStatusValid &&
      isOperatingStatusStartDateValid &&
      isRegistrationEndDateValid &&
      isVinValid &&
      isYearValid
    setIsFormDataValid(tempIsFormDataValid)

    const tempOperatingStatusInfoError =
      !isOperatingStatusValid || !isOperatingStatusStartDateValid
        ? OPERATING_STATUS_INFO_INVALID_ERROR
        : ''
    setOperatingStatusInfoError(tempOperatingStatusInfoError)
  }, [
    dotEndDate,
    name,
    inServiceDate,
    licensePlate,
    livery,
    location,
    make,
    model,
    operatingStatus,
    operatingStatusStartDate,
    registrationEndDate,
    vin,
    vinError,
    year,
    yearError,
  ])

  useEffect(() => {
    // If the in service date is changed to beyond the out of service date,
    // reset the out of service date
    if (moment(inServiceDate).isAfter(outOfServiceDate)) {
      setVanData({ outOfServiceDate: undefined })
    }
  }, [inServiceDate])

  const qrCodeObjectKey = images?.length
    ? images.find((image) => image.type === VAN_IMAGE_TYPES.qr_code)
        ?.s3objectKey
    : ''
  async function getQrCodeImage() {
    if (qrCodeObjectKey) {
      const qrCodeUrl = await fetchData<string>(
        getImageBys3ObjectKey(qrCodeObjectKey),
      )
      setQrCodeImageUrl(qrCodeUrl)
    }
  }
  useEffect(() => {
    if (qrCodeObjectKey) {
      getQrCodeImage()
    }
  }, [qrCodeObjectKey])

  function handleChangeOperatingStatusModalClosed() {
    setIsOperatingStatusModalOpen(false)
  }

  return (
    <div className={styles.formContainer}>
      <ChangeOperatingStatusModal
        isOpen={isOperatingStatusModalOpen}
        handleClosed={handleChangeOperatingStatusModalClosed}
        setOperatingStatusNote={setOperatingStatusNote}
      />
      <div className={styles.operatingStatusContainer}>
        <PrimaryButton
          buttonName='Change Operating Status'
          onClick={() => setIsOperatingStatusModalOpen(true)}
          disabled={isVanOutOfInventory}
        />
        <div className={styles.operatingStatusInfo}>
          {determineIsDateValid(operatingStatusStartDate)
            ? moment.utc(operatingStatusStartDate).format('M/D/YYYY')
            : 'N/A'}
          : {operatingStatus ?? 'N/A'}
        </div>
      </div>
      <div className={styles.top}>
        <div className={styles.topLeft}>
          <TextField
            name='name'
            label='Van Name'
            onChange={(e) => setVanData({ name: e.target.value })}
            value={name}
            required
            disabled={true}
          />
          <LocationDropDown
            onLocationChange={(val: string) => setLocation(val)}
            defaultValue={locationObjectId}
            label='Location'
            shouldIncludeDefault={false}
            required
            disabled={isVanOutOfInventory}
          />
          <TextField
            type='date'
            label='Registration End Date'
            name='registrationEndDate'
            InputLabelProps={{ shrink: true }}
            sx={{ width: '162px', display: '' }}
            onChange={(e) =>
              setVanData({
                registrationEndDate: transformDate(e.target.value) as Date,
              })
            }
            value={formatDate(registrationEndDate)}
            required
            disabled={isVanOutOfInventory}
          />
          <TextField
            type='date'
            label='DOT End Date'
            name='dotEndDate'
            InputLabelProps={{ shrink: true }}
            sx={{ width: '162px', display: '' }}
            onChange={(e) =>
              setVanData({ dotEndDate: transformDate(e.target.value) as Date })
            }
            value={formatDate(dotEndDate)}
            required
            disabled={isVanOutOfInventory}
          />
          <TextField
            type='date'
            label='In Service Date'
            name='inServiceDate'
            InputLabelProps={{ shrink: true }}
            sx={{ width: '162px', display: '' }}
            onChange={(e) =>
              setVanData({
                inServiceDate: transformDate(e.target.value) as Date,
              })
            }
            value={formatDate(inServiceDate)}
            required
            disabled={isVanOutOfInventory}
          />
          <TextField
            type='date'
            label='Out of Service Date'
            name='outOfServiceDate'
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: formatDate(inServiceDate) } }}
            sx={{ width: '162px', display: '' }}
            onChange={(e) =>
              setVanData({
                outOfServiceDate: transformDate(e.target.value) as Date,
              })
            }
            value={formatDate(outOfServiceDate)}
            disabled={isVanOutOfInventory}
          />
        </div>
        <div className={styles.topRight}>
          <TextField
            select
            name='livery'
            label='Livery'
            onChange={(e) => setVanData({ livery: e.target.value })}
            value={livery ?? ''}
            required
            disabled={isVanOutOfInventory}
          >
            {generateMenuItems(LIVERIES_ARRAY)}
          </TextField>
          <TextField
            name='licensePlate'
            label='License Plate'
            onChange={(e) => setVanData({ licensePlate: e.target.value })}
            value={licensePlate ?? ''}
            required
            disabled={isVanOutOfInventory}
          />
          <TextField
            disabled={
              isVanOutOfInventory ||
              !hasPermission(user).allow(
                PERMISSIONS.VANS.CATEGORY,
                PERMISSIONS.VANS.ENTRIES.EDIT_YEAR.NAME,
              )
            }
            name='year'
            label='Year'
            type='number'
            onChange={(e) => handleYearChange(e, setVanData, setYearError)}
            value={year ?? ''}
            error={Boolean(yearError)}
            required
          />
          <TextField
            disabled={
              isVanOutOfInventory ||
              !hasPermission(user).allow(
                PERMISSIONS.VANS.CATEGORY,
                PERMISSIONS.VANS.ENTRIES.EDIT_MAKE.NAME,
              )
            }
            name='make'
            label='Make'
            onChange={(e) => setVanData({ make: e.target.value })}
            value={make ?? ''}
            required
          />
          <TextField
            disabled={
              isVanOutOfInventory ||
              !hasPermission(user).allow(
                PERMISSIONS.VANS.CATEGORY,
                PERMISSIONS.VANS.ENTRIES.EDIT_MODEL.NAME,
              )
            }
            label='Model'
            onChange={(e) => setVanData({ model: e.target.value })}
            value={model ?? ''}
            required
          />
          <TextField
            disabled={
              isVanOutOfInventory ||
              !hasPermission(user).allow(
                PERMISSIONS.VANS.CATEGORY,
                PERMISSIONS.VANS.ENTRIES.EDIT_VIN.NAME,
              )
            }
            name='vin'
            label='Vin'
            onChange={(e) => handleVinChange(e, setVanData, setVinError)}
            value={vin ?? ''}
            error={Boolean(vinError)}
            inputProps={{ maxLength: MAX_VIN_LENGTH }}
            required
          />
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomLeft}>
          <div className={styles.qrCodeTitle}>Van Identification QR Code</div>
          <div
            className={styles.qrCodeContainer}
            style={{
              display: qrCodeImageUrl ? 'flex' : 'none',
            }}
          >
            <img
              src={qrCodeImageUrl}
              alt='qr code'
            />
          </div>
        </div>
        <div className={styles.bottomRight}>
          <BatteryData />
          <div className={styles.errorContainer}>
            {!isVanOutOfInventory && operatingStatusInfoError && (
              <FormHelperText
                className={styles.error}
                error
              >
                - {operatingStatusInfoError}
              </FormHelperText>
            )}
            {!isVanOutOfInventory && vinError && (
              <FormHelperText
                className={styles.error}
                error
              >
                - {vinError}
              </FormHelperText>
            )}
            {!isVanOutOfInventory && yearError && (
              <FormHelperText
                className={styles.error}
                error
              >
                - {yearError}
              </FormHelperText>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditVanFormComponent
