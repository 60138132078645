import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { useContext, useState } from 'react'
import { IBatteryData } from '../../../../../../../app/entities/BatteryData'
import SecondaryButton from '../../../../../global/components/buttons/secondary-button/SecondaryButton'
import { VanContext } from '../../../../../global/context/van-context/VanContext'
import { BatteryDataTable } from './BatteryDataTable'
import styles from './styles.module.css'

export function BatteryData() {
  const [isOpen, setIsOpen] = useState(false)
  const { vanData } = useContext(VanContext)
  const batteryData = (vanData.currentBatteryData ?? []) as IBatteryData[]
  function handleOpen() {
    setIsOpen(true)
  }
  function handleClose() {
    setIsOpen(false)
  }
  function getBatteryDataDisplay(batteryData: IBatteryData[]) {
    if (batteryData.length === 0) {
      return 'No battery data available'
    }
    return batteryData.map((data, index) => {
      return (
        <div key={index}>
          Battery:{' '}
          <span style={{ fontStyle: 'italic' }}>{data.batteryName}</span> @{' '}
          {data.stateOfCharge}%
        </div>
      )
    })
  }
  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <Box className={styles.batteryDataModal}>
          <div className={styles.tableContainer}>
            <BatteryDataTable />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <SecondaryButton
                buttonName='Close'
                onClick={handleClose}
              />
            </div>
          </div>
        </Box>
      </Modal>
      <div className={styles.subsectionTitle}>Battery Data</div>
      <button
        className={styles.stateOfChargeContainer}
        onClick={handleOpen}
      >
        {getBatteryDataDisplay(batteryData)}
      </button>
    </>
  )
}
