import { useState, useEffect, useMemo, useContext } from 'react'
import styles from './styles.module.css'
import { fetchData } from '../../../global/utils/fetch'
import { getFuturePercentBookedData } from './api'
import moment from 'moment'
import { COLUMN_DEFINITIONS } from '../scheduling-performance/helpers/column-definitions'
import { ToastNotificationContext } from '../../../global/context/toast-context/ToastNotificationContext'
import { formatDateYyyyMmDd } from '../../../global/utils/date/date-utils-yyyy-mm-dd'

interface D2ACalculations {
  minimum: number
  thirtyDayAverage: number
  maximum: number
}
interface FuturePercentBookedLocationData {
  d2a: D2ACalculations
  fourteenDayJobCapacities: number[]
  percentBookedArray: string[]
  fourteenDayWorkOrderCounts: number[]
}

interface FuturePercentBookedData {
  futurePercentBookedLocation: Record<string, FuturePercentBookedLocationData>
  totalData: (number | string)[]
}

function FuturePercentBooked({ date }: { date: Date }) {
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [isLoading, setIsLoading] = useState(true)
  const [tableState, setTableState] = useState<FuturePercentBookedData | null>(
    null,
  )

  async function fetchDashboardData() {
    try {
      const data = await fetchData<FuturePercentBookedData>(
        getFuturePercentBookedData(date),
      )
      setTableState(data)
    } catch (err) {
      console.error(err)
      setTableState(null)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: `There is no data for ${formatDateYyyyMmDd(date)}`,
        severity: 'error',
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchDashboardData()
  }, [date])

  const renderTableRows = useMemo(() => {
    if (!tableState) return <></>
    const locationData = tableState.futurePercentBookedLocation
    const [totalD2A, ...totalPercentBooked] = tableState.totalData
    const locationKeys = Object.keys(locationData)
    const rows = locationKeys.sort().map((locationName) => {
      const data: FuturePercentBookedLocationData = locationData[locationName]

      return (
        <>
          <tr className={styles.row}>
            <td className={`${styles.highlightedCell} ${styles.stickyRowOne}`}>
              {locationName}
            </td>
            <td
              className={`${styles.highlightedCellWithToolTip} ${styles.stickyRowTwo}`}
              data-tooltip={
                COLUMN_DEFINITIONS.FUTURE_PERCENT_BOOKED.d2a.minimum
              }
            >
              {`Min: ${data.d2a.minimum}`}
            </td>
            <td
              className={`${styles.highlightedCell} ${styles.stickyRowTwo} ${styles.test}`}
            >
              Work Orders
            </td>
            {data.fourteenDayWorkOrderCounts.map((workOrder) => (
              <td>{workOrder}</td>
            ))}
          </tr>
          <tr className={styles.row}>
            <td
              className={`${styles.highlightedCell} ${styles.stickyRowOne}`}
            ></td>
            <td
              className={`${styles.highlightedCellWithToolTip} ${styles.stickyRowTwo}`}
              data-tooltip={
                COLUMN_DEFINITIONS.FUTURE_PERCENT_BOOKED.d2a.average
              }
            >
              {`Avg: ${data.d2a.thirtyDayAverage}`}
            </td>
            <td
              className={`${styles.highlightedCell} ${styles.stickyRowTwo} ${styles.test}`}
            >
              Available Slots
            </td>
            {data.fourteenDayJobCapacities.map((slot) => (
              <td>{slot}</td>
            ))}
          </tr>
          <tr className={styles.row}>
            <td
              className={`${styles.highlightedCell} ${styles.stickyRowOne}`}
            ></td>
            <td
              className={`${styles.highlightedCellWithToolTip} ${styles.stickyRowTwo}`}
              data-tooltip={
                COLUMN_DEFINITIONS.FUTURE_PERCENT_BOOKED.d2a.maximum
              }
            >
              {`Max: ${data.d2a.maximum}`}
            </td>
            <td
              className={`${styles.highlightedCell} ${styles.stickyRowTwo} ${styles.test}`}
            >
              %Booked
            </td>
            {data.percentBookedArray.map((percent) => (
              <td>{percent}</td>
            ))}
          </tr>
        </>
      )
    })

    rows.push(
      <>
        <tr className={styles.row}>
          <td className={`${styles.highlightedCell} ${styles.stickyRowOne}`}>
            TRMI Total
          </td>
          <td className={`${styles.highlightedCell} ${styles.stickyRowTwo}`}>
            {totalD2A}
          </td>
          <td className={`${styles.highlightedCell} ${styles.stickyRowTwo}`}>
            %Booked
          </td>
          {totalPercentBooked.map((percent) => (
            <td>{percent}</td>
          ))}
        </tr>
      </>,
    )

    return rows
  }, [tableState])

  if (isLoading) {
    return (
      <div className={styles.loading}>
        Loading... (may take up to a minute to finish calculating)
      </div>
    )
  }

  const today = moment().utc().startOf('day')

  if (!tableState) return <></>
  return (
    <div className={styles.tableWrapperContainer}>
      <table className={styles.futurePercentBookedTable}>
        <thead>
          <tr>
            <th
              className={`${styles.headerCell} ${styles.stickyHeaderOne}`}
            ></th>
            <th className={`${styles.headerCell} ${styles.stickyHeaderTwo}`}>
              D2A
            </th>
            <th
              className={`${styles.headerCell} ${styles.stickyHeaderTwo}`}
            ></th>
            {Array.from({ length: 14 }, (_, i) => i + 1).map((i: number) => (
              <th
                className={styles.headerCell}
              >{`(${i}) ${today.clone().add(i, 'day').format('M/D')}`}</th>
            ))}
            <th className={styles.headerCell}>7day%</th>
            <th className={styles.headerCell}>10day%</th>
            <th className={styles.headerCell}>14day%</th>
          </tr>
        </thead>
        <tbody>{renderTableRows}</tbody>
      </table>
    </div>
  )
}

export default FuturePercentBooked
