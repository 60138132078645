import { useContext } from 'react'
import { Column } from 'react-table'
import {
  IBatteryData,
  IBatteryDataPartial,
} from '../../../../../../../app/entities/BatteryData'
import TableTitle from '../../../../../global/components/table/helpers/table-title/TableTitle'
import TableTopper from '../../../../../global/components/table/helpers/table-topper/TableTopper'
import Table from '../../../../../global/components/table/Table'
import { VanContext } from '../../../../../global/context/van-context/VanContext'

export function BatteryDataTable() {
  const { vanData } = useContext(VanContext)
  const batteryData = (vanData.currentBatteryData ?? []) as IBatteryData[]

  const columns: Column<IBatteryData>[] = [
    {
      Header: 'Battery',
      accessor: (data: IBatteryData) => {
        return data.batteryName
      },
    },
    {
      Header: 'State of Charge',
      accessor: (data: IBatteryDataPartial) => {
        const stateOfCharge = data.stateOfCharge
        return `${stateOfCharge}%`
      },
    },
    {
      Header: 'Voltage',
      accessor: (data: IBatteryDataPartial) => {
        const voltage = data.voltage?.toFixed(2)
        return `${voltage} V`
      },
    },
    {
      Header: 'Capacity',
      accessor: (data: IBatteryDataPartial) => {
        const capacity = data.capacity?.toFixed(2)
        return `${capacity} Ah`
      },
    },
    {
      Header: 'Temperature',
      accessor: (data: IBatteryDataPartial) => {
        const temperature = data.temperature?.toFixed(1)
        return `${temperature}˚F`
      },
    },
  ]

  return (
    <>
      <TableTopper isOpen={true}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TableTitle>BATTERY DATA</TableTitle>
        </div>
      </TableTopper>
      <Table
        columns={columns}
        data={batteryData as any}
        onRowClick={() => {}}
        isOpen={true}
        isDisplayMode={true}
      />
    </>
  )
}
