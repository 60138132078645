import styles from './styles.module.css'
import Switch from '@mui/material/Switch'
import { Select } from '../../../../../global/components/select/Select'
import OrderSummary from '../order-summary/OrderSummary'
import { IScheduleModalComponentProps } from '../container-modal/ScheduleModal'
import React, { forwardRef, useEffect, useState, Suspense } from 'react'
import useFetch from '../../../../../global/hooks/useFetchHook'
import { getFreeOrderReasons } from './api'
import CircularProgress from '@mui/material/CircularProgress'
import { MODES } from '../../../../../global/constants/scheduler'
import { IFreeOrderReason } from '../../../../../../../app/entities/FreeOrderReason'

const BraintreePayment = React.lazy(() => import('./BraintreePayment'))

export const PaymentInformation = forwardRef(
  (props: IScheduleModalComponentProps, ref) => {
    const {
      isCardOnFileCallback,
      mode,
      schedulerState,
      setDisabled,
      setSchedulerState,
    } = props
    const { freeOrderReason, shouldRequestPaymentAfterScheduling } =
      schedulerState
    const { data } = useFetch<IFreeOrderReason[]>(getFreeOrderReasons())
    const [isLoading, setIsLoading] = useState(false)
    const [
      shouldDisableFreeOrderReasonDropDown,
      setShouldDisableFreeOrderReasonDropDown,
    ] = useState(!schedulerState.freeOrderReason.isFree)

    useEffect(() => {
      if (freeOrderReason.isFree && freeOrderReason.objectId === '') {
        setDisabled(true)
      } else if (freeOrderReason.isFree && freeOrderReason.objectId !== '') {
        setDisabled(false)
      } else {
        setDisabled(false)
      }
    }, [freeOrderReason, setDisabled])

    const shouldHidePaymentInformation =
      freeOrderReason.isFree || shouldRequestPaymentAfterScheduling

    if (mode === MODES.CUSTOMER) {
      return (
        <div className={styles.mainPaymentInformationContainer}>
          {isLoading && (
            <div className={styles.overlay}>
              <div className={styles.progressContainer}>
                <CircularProgress size={80} />
              </div>
            </div>
          )}
          <div className={styles.noChargeAndPaymentInfoContainer}>
            <Suspense fallback={<CircularProgress />}>
              <div>
                <BraintreePayment
                  ref={ref}
                  orderId={schedulerState.orderId}
                  setAffirmativeDisabled={props.setDisabled}
                  schedulerState={schedulerState}
                  setIsLoading={setIsLoading}
                  isCardOnFileCallback={isCardOnFileCallback}
                />
              </div>
            </Suspense>
          </div>
          <OrderSummary {...props} />
        </div>
      )
    }
    return (
      <div className={styles.mainPaymentInformationContainer}>
        {isLoading && (
          <div className={styles.overlay}>
            <div className={styles.progressContainer}>
              <CircularProgress size={80} />
            </div>
          </div>
        )}
        <div className={styles.noChargeAndPaymentInfoContainer}>
          <div
            className={styles.noChargeContainer}
            style={{ visibility: mode === 'edit' ? 'hidden' : 'visible' }}
          >
            <div className={styles.switchContainer}>
              <Switch
                name='freeOrder'
                onChange={(e) => {
                  const shouldClearFreeOrderReason = !e.target.checked
                  setShouldDisableFreeOrderReasonDropDown(
                    shouldClearFreeOrderReason,
                  )
                  setSchedulerState((prevState: any) => ({
                    ...prevState,
                    freeOrderReason: {
                      objectId: shouldClearFreeOrderReason
                        ? ''
                        : prevState.freeOrderReason.objectId,
                      reason: shouldClearFreeOrderReason
                        ? ''
                        : prevState.freeOrderReason.reason,
                      isFree: e.target.checked,
                    },
                    shouldRequestPaymentAfterScheduling: false,
                  }))
                }}
                checked={freeOrderReason.isFree}
              />
              <div className={styles.noChargeLabel}>Free, no charge</div>
            </div>
            {data && (
              <Select
                label='Reason for no charge'
                name='Free Order Reason'
                data={data}
                width='222px'
                defaultValue={freeOrderReason.objectId}
                disabled={shouldDisableFreeOrderReasonDropDown}
                onChange={(e: any) => {
                  setSchedulerState((prevState: any) => ({
                    ...prevState,
                    freeOrderReason: {
                      ...prevState.freeOrderReason,
                      objectId: e.target.value,
                    },
                  }))
                }}
              />
            )}
            <div className={styles.switchContainer}>
              <Switch
                name='shouldRequestPaymentAfterScheduling'
                onChange={(e) => {
                  setShouldDisableFreeOrderReasonDropDown(true)
                  setSchedulerState((prevState: any) => ({
                    ...prevState,
                    shouldRequestPaymentAfterScheduling: e.target.checked,
                    freeOrderReason: {
                      objectId: '',
                      reason: '',
                      isFree: false,
                    },
                  }))
                }}
                checked={schedulerState.shouldRequestPaymentAfterScheduling}
              />
              <div className={styles.noChargeLabel}>
                Request Payment After Scheduling
              </div>
            </div>
          </div>
          <div style={{ display: shouldHidePaymentInformation ? 'none' : '' }}>
            <Suspense fallback={<CircularProgress />}>
              <div>
                <BraintreePayment
                  ref={ref}
                  orderId={schedulerState.orderId}
                  setAffirmativeDisabled={props.setDisabled}
                  schedulerState={schedulerState}
                  setIsLoading={setIsLoading}
                  isCardOnFileCallback={isCardOnFileCallback}
                />
              </div>
            </Suspense>
          </div>
        </div>
        <OrderSummary {...props} />
      </div>
    )
  },
)
