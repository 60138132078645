import { ReactNode, useMemo, ReactElement } from 'react'
import styles from './styles.module.css'
import linksData from '../tsx/links'
import { useLocation } from 'react-router-dom'

interface MainPageWrapperProps {
  children: ReactNode
  title?: string
  titleFields?: ReactElement
  stickyTitle?: boolean //boolean to make title & titleFields sticky
}

function MainPageWrapper({
  children,
  title,
  titleFields,
  stickyTitle,
}: MainPageWrapperProps) {
  const links = useMemo(() => linksData, [])
  const location = useLocation()

  const activeLink = links.find((link) => link.to === location.pathname)

  return (
    <div className={styles.mainPageWrapperMain}>
      <div className={styles.paddingDiv}></div>
      <div
        className={`${styles.mainPageWrapperTitle} font--bold ${stickyTitle ? `${styles.stickyHeader}` : ''}`}
      >
        {title || (activeLink && activeLink.title) || ''}
        {titleFields}
      </div>
      <div className={`${styles.mainPageWrapperContainer}`}>{children}</div>
    </div>
  )
}

export default MainPageWrapper
